import InputAtom from "../atoms/InputAtom";
import styled from 'styled-components';
import SelectAtom from "../atoms/SelectAtom";
import OutlineInputAtom from "../atoms/OutlineInputAtom";
// import { styled } from "@mui/system";

const CustomPhone = styled.div`
    display : flex;
    width : 325px;
    justify-content : space-between;
`

const PhoneNumberMolecule = ({
    setter,
    getter,
    feed,
    control,
    names = [],
    req,
    errors,
    label
}) => {
    return <CustomPhone>
        <SelectAtom
            setter={setter}
            getter={getter}
            feed={feed}
            control={control}
            name={names[0] || 'code'}
            req
            error={Boolean(errors?.code)}
            label={label} 
            />
        <OutlineInputAtom
            label={"Phone"} 
            type="tel" 
            feed={feed} 
            name={names[1] || 'number'} 
            req 
            error={Boolean(errors?.phoneNumber)} 
            mask={true} 
            />
    </CustomPhone>
}

export default PhoneNumberMolecule;