import React, { useState, forwardRef, useImperativeHandle } from 'react';
import OtpInput from 'react-otp-input';


const OtpAtom = forwardRef(({
    callbackFn
},ref) => {
    const [otp, setOtp] = useState('');
    const [locked, setLocked] = useState(false);

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);

        if (otpValue.length === 6) {
            setLocked(true);
            submitOtp(otpValue);
        }
    };

    useImperativeHandle(ref,()=>({
        resetHandler
    }))

    const resetHandler = () => {
        setLocked(false);
        setOtp('');
    }

    const submitOtp = (otpValue) => {
        console.log('Submitting OTP:', otpValue);
        callbackFn(otpValue);
    };

    return (
        // <div className='otp-input-container'>

            <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={
                    ({ ref, ...props }) => (
                        <input
                            {...props}
                            id="babyflix_otp"
                            ref={ref}
                            disabled={locked}
                        />
                    )
                }
            />
        // </div>
    );
})
export default OtpAtom;