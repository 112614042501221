import { Alert } from "@mui/material"

const AlertAtom = ({message="some alert",feel="info"}) => {
    return <Alert
        severity={feel}
    sx={{
        margin : "1rem 0",
        // borderRadius : theme => theme.pe
        // width : "90%"
    }}
        // action={
        //     <Button color="inherit" size="small">
        //         UNDO
        //     </Button>
        // }
    >
        {message}
    </Alert>
    
}

export default AlertAtom;