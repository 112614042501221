import React from 'react';
import styled from 'styled-components'

const CardWrapper = styled.div`
    border-radius: 10px;
    min-width: 500px;
    min-height: 500px;
    background-color: #ffffff26;
    box-shadow: 0 0 3px 0px grey;
    // ${props => props.className}
    `;

const Card = ({children}) => {
    return <CardWrapper className={''}>{children}</CardWrapper>;
}

export default Card;