import * as React from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, OutlinedInput, TextField } from '@mui/material';
import styled from 'styled-components';
import { styled as MuiStyle } from "@mui/system";
import PhoneList from "../../utils/CountryCodes";

const CustomPhone = styled.div`
    display : flex;
    width : 325px;
    justify-content : space-between;
`
const CustomMuiPhone = MuiStyle(OutlinedInput)({
  width: "65%",
})
const CustomMuiAutocomplete = MuiStyle(Autocomplete)({
  width: "30%",
})

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function NumberAtom({
  error = false,
  subText = "",
  feed = () => { },
  name = "",
  req = false
}) {
  // const [values, setValues] = React.useState({
  //   textmask: '000-000-0000',
  // });

  // const handleChange = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  return (
    <CustomPhone>
      <CustomMuiAutocomplete
        disablePortal
        options={PhoneList}

        {...feed("phoneCode",{required : req})}
        defaultValue = {PhoneList[0]}
        renderInput={(params) => <TextField
          {...params}
          // fullWidth 
          error={error}
        />}
      />
      <CustomMuiPhone
        // placeholder={values.textmask}
        // onChange={handleChange}
        placeholder={"555-555-5555"}
        fullWidth
        inputComponent={TextMaskCustom}
        error={error}
        {...feed(name, { required: req })}
      />
    </CustomPhone>
  );
}