import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { styled as MuiStyle } from "@mui/system";
import PhoneList from "../../utils/CountryCodes";
import { Controller } from 'react-hook-form';


// const CustomMuiAutocomplete = MuiStyle(Autocomplete)({
//   width: "30%",
// })




export default function SelectAtom({
  error = false,
  feed = () => { },
  control = () => { },
  setter = ()=>{},
  getter =()=>{},
  name = "",
  req = false,
  label = ""
}) {

    return <Controller
    name={name} // Replace with your field name
    control={control}
    rules={{ required: req }} // Set the 'required' rule
    render={({ field }) => (
      <Autocomplete
        {...field}
        options={PhoneList}
        sx={{
          width : "30%"
        }}
        renderInput={(params) => <TextField {...params} label={label} error={error} />}
        onChange={(e, value) => {
            setter(name, value); // Set the value with setValue
          }}
        value={getter(name) || ''}
      />
    )}
  />
}