import "./pageStyle.css";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
const {REACT_APP_SITE_KEY} = process.env;

const CaptchaPage = () => {
    const navigate = useNavigate();

    function onChange(value) {
        console.log(`Babyflix_token:${value}`);
        navigate(`/captcha?token=${value}`);
    }
    return (
        <div className="full_screen flex_col">
                <ReCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    onChange={onChange}
                />                
        </div>
    )
}

export default CaptchaPage;