import { DialogContentText } from "@mui/material";

const terms = (
    <>
        <DialogContentText>BabyFlix provides members an easy, safe, and fun way to create and share Videos (collectively, the “Service”). Before you continue using the BabyFlix website located at <a href="http://www.babyFlix.net">www.babyFlix.net</a> (the “Site”) or use any of the BabyFlix services, it is important that you carefully review the Terms and Conditions set out below (the “Terms”). BY USING THE SITE, YOU AGREE TO THESE TERMS; IF YOU DO NOT AGREE, DO NOT USE THE SITE.<br />
            Specific pages on the Site may set out additional terms and conditions, all of which are incorporated by reference into these Terms. These Terms may be changed or updated at any time, but you can always find the most recent version here. In the case of inconsistencies between these Terms and information included in other materials (for example, promotional materials and mailers), these Terms will always control. We recommend that you periodically check this page to make sure you are up to date.<br />
            By entering and using the Site, you indicate that you accept these Terms and that you agree to be bound by them. Acceptance of these Terms creates a binding contract between you and BabyFlix that you will use the Service only in a manner consistent with these Terms. If you have questions about these Terms, please contact us at <a href="mailto:support@babyflix.net">support@babyflix.net</a>. Your use of the Site and the Service is entirely conditioned on and subject to your compliance with these Terms. If you do not agree with these Terms, do not access or use the Service.<br />
            The Service is for personal use only; use of the Service for commercial use or for broadcast or distribution to persons outside of a normal circle of a family and its social acquaintances is expressly prohibited.  A scan uploaded to a Member’s account must be loaded through the clinic’s account and not directly to a member created account.  Clinic’s uploading content directly to member created accounts are subject to fines and penalties as well as the content being archived.  Member created accounts are meant for users to upload their own content.<br />
            To register as a member of BabyFlix, we require that you be 13 years or older.<br />
            By using our Service you agree to be bound by Section 1 of these Terms, which contains provisions applicable to all users of our Service. Our Service offers three classes of memberships:<br />
            1. Members who have registered with the Service and provided their email address, password;<br />
            2. Guests whom members have invited to view their Videos through the Service;<br />
            3. Visitors who visit <a href="http://www.babyflix.net">www.babyflix.net</a> and have no privileges to use the Service.</DialogContentText>
        <DialogContentText>Section 1: Applies to all registered users of the service</DialogContentText>
        <DialogContentText>1. Operator<br />
            This Service is operated by BabyFlix, Inc., a company incorporated under the laws of the State of California whose principal business address is BabyFlix, Inc., 310 Century Circle, Danville, CA 94526.<br />
            BabyFlix is also referred to in these Terms as “we” and “us”.</DialogContentText>
        <DialogContentText>2. Purpose of the Service<br />
            BabyFlix offers a web-based video creation service where registered users receive their ultrasound scan imagery directly from their scan, upload their own content, create a video, share it online, and get a download or DVD.</DialogContentText>
        <DialogContentText>3. Trademarks<br />
            All brand, product, and service names used in this Service which identify BabyFlix or third parties and their products and services are proprietary marks of BabyFlix and/or the relevant third parties. Nothing in this Service shall be deemed to confer on any person any license or right on the part of BabyFlix or any third party with respect to any such image, logo or name.</DialogContentText>
        <DialogContentText>4. Availability<br />
            BabyFlix uses reasonable endeavors to ensure that the Service is available 24 hours a day, 7 days a week. However, there will be occasions when the Service will be interrupted for maintenance, upgrades, and emergency repairs or due to failure of telecommunications links and equipment or other circumstances that are beyond the control of BabyFlix. Reasonable steps will be taken by us to minimize such disruption where it is within the reasonable control of BabyFlix. From time to time, and without notice to you, BabyFlix may add or delete certain features or function from the Service we provide. You agree that BabyFlix shall not be liable to you for any modification, suspension or discontinuance of the Service. While we do whatever we can to keep the site up, you are responsible for having backups of your content.  Please download everything in your account to ensure you have a backup.  We guarantee your content will be available for a period of one year after which there will be a charge to host content on BabyFlix.  Content may be archived if the terms in this agreement are violated.</DialogContentText>
        <DialogContentText>5. Customized Content<br />
            a. The BabyFlix Service permits the submission of videos or other communications submitted by you and other users (“Customized Content”), and the hosting, sharing and/or publishing of such Customized Content. You understand that whether or not such Customized Content is published, BabyFlix does not guarantee any confidentiality with respect to any submissions, except as set forth in the Privacy Policy. BabyFlix will maintain creations for up to 90 days in free accounts with no activity. You must sign in at least once every 90 days in order to retain your Free Membership. Otherwise, BabyFlix reserves the right to cancel your membership and remove your creations.</DialogContentText>
        <DialogContentText> b. You shall be solely responsible for your own Customized Content and the consequences of posting or publishing them. In connection with Customized Content, you affirm, represent and/or warrant that: (i) you own, or have the necessary licenses, rights, consents, and permissions to use and authorize BabyFlix to use, all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all Customized Content to enable inclusion and use of the Customized Content in the manner contemplated by the Website and these Terms of Use; and (ii) you have the written consent, release, and /or permission of each and every identifiable individual person in the User Submission to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of the Customized Content in the manner contemplated by the Website and these Terms of Use. For clarity, you shall retain all of your ownership rights in your Customized Content. However, by submitting the Customized Content to BabyFlix, you hereby grant BabyFlix a limited worldwide, non-exclusive, fully paid-up, royalty-free, irrevocable, perpetual, sublicenseable and transferable license to use, reproduce, distribute (in any media formats and through any media channels), prepare derivative works of, display, perform and otherwise exploit the Customized Content as part as of the services provided by BabyFlix, Inc.</DialogContentText>
        <DialogContentText> c. In connection with Customized Content, you further agree that you will not: (i) submit material that is copyrighted, protected by trade secret or otherwise subject to third party proprietary rights, including privacy and publicity rights, unless you are the owner of such rights or have permission from their rightful owner to post the material and to grant BabyFlix all of the license rights granted herein; (ii) publish falsehoods or misrepresentations that could damage BabyFlix or any third party; (iii) submit material that is unlawful, obscene, defamatory, libelous, threatening, pornographic, harassing, hateful, racially or ethnically offensive or encourages conduct that would be considered a criminal offense, give rise to civil liability, violate any law or is otherwise inappropriate; (iv) post advertisements or solicitations of business: (v) impersonate another person. BabyFlix does not endorse any User Submission or any opinion, recommendation or advice expressed therein, and BabyFlix expressly disclaims any and all liability in connection with Customized Content. If notified by a user or a content owner of a User Submission that allegedly does not conform to this Agreement, BabyFlix may investigate the allegation and determine in good faith and in its sole discretion whether to remove the User Submission, which it reserves the right to do at any time. For clarity, BabyFlix does not permit copyright infringing activities on its Website, and reserves the right to terminate access to the Website, and remove all Content submitted, by any persons who are found to be repeat infringers.</DialogContentText>
        <DialogContentText> d. You understand that when using the BabyFlix Website you will be exposed to Customized Content from a variety of sources, and that BabyFlix is not responsible for the accuracy, usefulness, safety, or intellectual property rights of or relating to such Customized Content. You further understand and acknowledge that you may be exposed to Customized Content that are inaccurate, offensive, indecent or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against BabyFlix with respect thereto, and agree to indemnify and hold BabyFlix, its Owners/Operators, affiliates, and/or licensors, harmless to the fullest extent allowed by law regarding all matters related to your use of the site.</DialogContentText>
        <DialogContentText> e. BabyFlix permits you to link to Your own Customized Content hosted on the website or Customized Content of other third parties available on the Website, for personal, non-commercial purposes only. In addition, BabyFlix provides an “Embedded Player” feature, in which you can incorporate certain Customized Content on your own personal, non-commercial websites, provided that you include a prominent link back to the BabyFlix website on the pages containing the Embedded Player. You understand that the Customized Content, whether or not linked or embedded into other web sites, are provided to You only on an as-available basis, and BabyFlix does not guarantee that their availability will be uninterrupted or bug free. BabyFlix reserves the right to discontinue any aspect to the BabyFlix Website at any time, including discontinuing any linked or embedded Content either generally or in specific cases.</DialogContentText>
        <DialogContentText>6. Amendment of the Terms<br />
            We reserve the right to amend these Terms from time to time.</DialogContentText>
        <DialogContentText>7. Claims, Controlling Law and Language<br />
            Any action hereunder must be brought, if at all, within one (1) year from the accrual of the cause of action. The parties agree that any claims hereunder shall be subject to the state and federal laws of the state of California, and hereby consent to jurisdiction and venue in the courts of the Northern District of California located in San Francisco, California. Both parties expressly exclude the application of the United Nations Convention of Contracts for the International Sale of Goods, if applicable, to these Terms of Use. The parties hereto confirm that it is their wish that these Terms of Use, as well as other documents relating hereto, including notices, have been shall be written in the English language only.</DialogContentText>
        <DialogContentText>8. General<br />
            These Terms constitute the entire agreement between BabyFlix and you with respect to your use of the Service. BabyFlix’s failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision. If a court should find that one or more rights or provisions contained in these Terms is invalid, you agree that the remainder of the Terms shall be enforceable.</DialogContentText>
        <DialogContentText>1.  TERMS AND CONDITIONS<br />
            Member’s use of the licensed Software is subject to the following:<br />
            1.1 General.  Subject to the terms and conditions of this Agreement, BabyFlix grants and member accepts a nonexclusive, nontransferable, and license for the Registered Users to access and use the functionality of the Software during the term of this Agreement.  The member shall not permit any other person or entity to access or use their account.<br />
            1.2 Member Modifications and Enhancements. Member may not make any modifications or enhancements to the Software without BabyFlix’s prior written consent.<br />
            1.3 Proper Use of Software.  The member acknowledges that the continued integrity of the Software and BabyFlix’s performance of its obligations described in this Agreement are dependent upon Customer’s use of the Software in accordance with the documentation available to member at the terms and conditions of this Agreement.<br />
            1.4 OWNERSHIP AND PROPRIETARY RIGHTS.  Member may not attempt to sell, sublicense, lease, permit, rent or transfer in any way whatsoever the Software.  Customer agrees that it will not, at any time, without the prior written consent of BabyFlix, decompile, disassemble or reverse engineer any software included within the Software, including without limitation the applications, to develop functionally similar Software or permit any third party to do any of the foregoing.  Customer agrees to not grant access to any 3rd party for any purpose without the prior written consent of BabyFlix.<br />
            1.5 BabyFlix shall indemnify, defend, and hold member harmless from any action against Customer to the extent that it is based on an allegation that the Software has infringed an intellectual property right or trade secret and pay those damages or costs related to the settlement of such action or finally awarded against member in such action, including but not limited to attorney’s fees, provided that, (a) member promptly notifies BabyFlix of such action, (b) gives BabyFlix full authority, information and assistance to defend such claim, and (c) gives BabyFlix control of the defense of such claim.<br />
            1.6 Sales tax will be charged unless a sales tax exemption form is presented.</DialogContentText>
        <DialogContentText>2. SERVICES TO BE PROVIDED<br />
            2.1    BabyFlix shall provide Customer support for the Software during business hours, which are 8:00 a.m. to 6:00 p.m. Pacific Standard Time, Monday through Friday, excluding holidays (the “Business Hours”).  BabyFlix is not responsible for issues related to Customer’s computer or internal and external computer network.</DialogContentText>
        <DialogContentText>3. WARRANTIES<br />
            3.1 BabyFlix will maintain the confidentiality of information regarding any patient media or data.<br />
            3.2 BabyFlix warrants that it either owns or has the right to license the Services hereunder.  BabyFlix warrants that the Services provided hereunder will be performed in a competent and workmanlike manner, which meets or exceeds industry standards.<br />
            3.3   Customer acknowledges that data conversion and transmission is subject to the likelihood of human and machine errors, omissions, delays and losses, including inadvertent loss of data or damage to media, that may give rise to loss or damage.  BabyFlix shall not be liable for any such errors, omissions, delays, or losses.  Customer is responsible for adopting reasonable measures to limit the impact of such problems, including adopting procedures to ensure the accuracy of input data; examining and confirming results prior to use; and adopting procedures to identify and correct errors and omissions.  Customer understands and agrees that use of or connection to the Internet is inherently insecure and that connection to the Internet provides opportunity for unauthorized access by a third party to computer systems, networks, and any and all information stored therein.  All information transmitted and received through the Internet is subject to unauthorized interception, diversion, corruption, loss, access, and disclosure.  BabyFlix and its suppliers shall not be responsible for any adverse consequences whatsoever of Customer’ or any End-User’ connection to or use of the Internet, and shall not be responsible for any use by Customer or any End User of an Internet connection in violation of any law, rule, or regulation or any violation of the intellectual property rights of another.<br />
            3.4 OTHER THAN AS EXPRESSLY SET FORTH ABOVE, BABYFLIX DOES NOT MAKE ANY EXPRESS OR IMPLIED WARRANTIES, CONDITIONS, OR REPRESENTATIONS TO CUSTOMER, ANY OF ITS AFFILIATES OR ANY OTHER PARTY WITH RESPECT TO THE APPLICATIONS, SERVICES OR ANY PRODUCTS, DOCUMENTATION, OR ANY OTHER SERVICES OR WORKS OF AUTHORSHIP PROVIDED HEREUNDER OR OTHERWISE REGARDING THIS AGREEMENT, ANY IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY, NONINFRINGEMENT, OR FITNESS FOR A PARTICULAR PURPOSE ARE EXPRESSLY EXCLUDED AND DISCLAIMED.<br />
            3.5  LIMIITATION OF LIABILITY.  BABYFLIX’S LIABILITY TO CUSTOMER FOR ANY LOSSES OR INDIRECT DAMAGES, IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF THE SUBJECT MATTER OF THIS AGREEMENT SHALL BE LIMITED TO THOSE ACTUAL AND DIRECT DAMAGES WHICH ARE REASONABLY INCURRED BY CUSTOMER AND SHALL NOT EXCEED THE FEES PAID BY CUSTOMER WITH RESPECT TO THE SERVICES GIVING RISE TO THE LIABILITY OVER THE MONTHS IN WHICH LIABILITY OCCURRED NOT TO EXCEED SIX (6) MONTHS.  BABYFLIX WILL NOT BE LIABLE FOR:  (I) SPECIAL, PUNITIVE, INDIRECT, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR LOSS OF DATA, LOST PROFITS, LOSS OF GOODWILL IN ANY WAY ARISING FROM OR RELATING TO THIS AGREEMENT, THE APPLICATIONS OR SERVICES, EVEN IF BABYFLIX HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES OCCURRING.</DialogContentText>
        <DialogContentText>BabyFlix Privacy Policy is designed to help you:</DialogContentText>
        <DialogContentText>* Understand what information we collect and how we collect, share, and use the information; and<br />
            * Make informed decisions when using the services located at <a href="http://www.babyflix">www.babyflix</a> (“BabyFlix” or “Web Site”)<br />
            * By using or accessing BabyFlix.net, you are accepting the practices described in this Privacy Policy, as modified by us from time to time.</DialogContentText>
        <DialogContentText>The Information We Collect<br />
            When you visit BabyFlix.net, you provide us with two types of information:<br />
            * Personal Information that you knowingly choose to disclose through various Web Site features.<br />
            * Web Site Use Information that is collected by us as you interact with our Web Site.</DialogContentText>
        <DialogContentText>Personal Information You Provide to Us<br />
            * We collect “Personal Information” that you or the clinic submit to us voluntarily through our Web Site.<br />
            * When you or a clinic register with BabyFlix, you provide certain Personal Information, including your name, email address, mobile phone and password.<br />
            * When you or a clinic create a profile on the Web Site after registering, you may provide material that may contain Personal Information, such as a personal photo, email address, or a “headline.”<br />
            * When you or a clinic post any material (such as User Content) on the Web Site, you may be providing Personal Information.<br />
            * When you order products or services on BabyFlix, you provide Personal Information necessary to complete the transaction, including your name, credit card information, shipping information and billing information.<br />
            * When you contact us to give feedback or ask a question using the forms on the Web Site, you provide your name, email address and your comment, question or feedback.<br />
            * When you participate in our surveys, we may collect additional Personal Information.<br />
            * When you contact us by sending us an email, we will collect your email address, any Personal Information contained in the email, and any Personal Information attached to the email.</DialogContentText>
        <DialogContentText>Web Site Use Information<br />
            When you access BabyFlix, we collect non-personally-identifiable information including your browser type, operating system, domain names, and IP Address. We use different types of technologies to collect this information, including single-pixel GIFs. In addition, we store certain information from your browser using “cookies.” A cookie is a piece of data stored on the user’s computer tied to information about the user. We use session ID cookies to confirm that users are logged in. These cookies terminate when the user closes the browser. Under other circumstances, including if you select the “Remember Me” option upon login, we use a persistent cookie, for example, to store your login ID (but not your password) to allow you to automatically login when you come back to BabyFlix. You can set your browser to refuse cookies; however, if you do so, some or all of the Web Site may not function properly or at all.</DialogContentText>
        <DialogContentText>User Content and Other Information<br />
            When you use BabyFlix, you may form relationships, send messages, perform searches and queries, and transmit Personal Information through various channels. We collect Personal Information related to these services so that we can provide you the service and offer personalized features. In most cases, we retain the Personal Information so that, for instance, you can view a list of your friends or see the books you are currently contributing to. When you update your Personal Information, we sometimes keep a backup copy of the prior version for a reasonable period of time to enable reversion to the prior version of that information.</DialogContentText>
        <DialogContentText>You post Personal Information in User Content on the Web Site at your own risk. Although we allow you to set privacy options that limit access to your content, please be aware that no security measures are perfect or impenetrable. We cannot control the actions of other users with whom you may choose to share your content and Personal Information. Therefore, we cannot and do not guarantee that User Content you post on the Web Site will not be viewed by unauthorized persons. We are not responsible for circumvention of any privacy settings or security measures contained on the Web Site. You understand and acknowledge that, even after removal, copies of User Content may remain viewable in cached and archived pages or if other users have copied or stored your User Content.<br />
            If you choose to use our invitation service to tell friends about our Web Site or invite them to collaborate on a book, we will ask you for your friend’s email address. Your friend will automatically be sent a one-time email inviting him or her to visit the Web Site. BabyFlix stores information gathered during this process to register a friend connection if your invitation is accepted, and to track the success of our referral program. Your friend may contact us at <a href="mailto:support@babyflix.net">support@babyflix.net</a> to request that we remove your friend’s information from our database.</DialogContentText>
        <DialogContentText>By using BabyFlix, you are consenting to have your personal data transferred to and processed in the United States.</DialogContentText>
        <DialogContentText>Use of Information Obtained by BabyFlix<br />
            In general, information you submit to us is used either to respond to requests that you make or to aid us in serving you better, including to fix technical problems, provide administrative services, and monitor traffic, the number of visitors, and the effectiveness of programs. We may use aggregated user data to market the Web Site to others. We may provide these services ourselves or share the information with partners who will provide the services for us.</DialogContentText>
        <DialogContentText>We may use your Personal Information to provide you with newsletters and surveys and to notify you of important changes to the Web Site, new services, and special offers and in  medical research. If you do not want to receive such email notices, you may login to your account at the Web Site and opt out of receiving such emails or medical research by sending an email to <a href="mailto:support@babyflix.net">support@babyflix.net</a>. Opting out may prevent you from receiving email messages regarding updates, improvements, or special offers or participating in medical research. If we have provided any third party with your information, you will have to contact the third-party directly with any opt-out request.</DialogContentText>
        <DialogContentText>Profile information is used by BabyFlix primarily to be presented back to and edited by you when you access BabyFlix and to be presented to others you permit to view that information.</DialogContentText>
        <DialogContentText>User Content in your account will be displayed to users with whom you have a relationship through your profile, search results and various other pages on the Web Site. In addition, the title, page count, thumbnail photo, description, author and list of contributors of your content will be visible to all users you shared with.</DialogContentText>
        <DialogContentText>Links<br />
            BabyFlix may contain links to other web sites. We are of course not responsible for the privacy practices of other web sites. We encourage our users to be aware when they leave our Web Site to read the privacy statements of each and every web site that collects personally identifiable information. This Privacy Policy applies solely to information collected via BabyFlix.</DialogContentText>
        <DialogContentText>Changing or Removing Information<br />
            Access and control over most Personal Information on BabyFlix is readily available through your account settings. BabyFlix users may modify or remove their information through the Gallery page or by contacting customer support @ <a href="mailto:support@babyflix.net">support@babyflix.net</a>. Information will be updated immediately.</DialogContentText>
        <DialogContentText>If you make use of the communication features of the service to share information with other individuals on BabyFlix (e.g., sending a message), you generally cannot remove or edit such communications. BabyFlix may delete your information from its system, at its sole discretion, at any time.</DialogContentText>
        <DialogContentText>Security<br />
            BabyFlix takes precautions to protect our users’ Personal Information; however, no precautions are 100% effective. As a result, BabyFlix does not guarantee that Personal Information you provide to us will remain secure. Your account information is located on a secured server behind a firewall. Because email and instant messaging are not recognized as secure communications, we request that you not send private information to us by email or instant messaging services.</DialogContentText>
        <DialogContentText>Terms of Use, Notice and Revisions<br />
            If you choose to visit BabyFlix, your visit and any dispute over privacy is subject to the then-current Privacy Policy and our Terms of Use, including limitations and damages, arbitration of, and application of law of the state of California. We reserve the right to change our Privacy Policy at any time by posting any changes on the Web Site. All changes will take effect immediately upon their posting on the Web Site. If we make changes, we will indicate at the top of the page the policy’s effective date. We therefore encourage you to refer to this policy on an ongoing basis so that you understand our current privacy policy. Your use of the Web Site after any changes to the Privacy Policy constitutes your acceptance of the new Privacy Policy. Unless stated otherwise, our current Privacy Policy applies to all information that we have about you and your account.</DialogContentText>
        <DialogContentText>Contacting BabyFlix<br />
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@babyflix.net">support@babyflix.net</a>.</DialogContentText>
        <DialogContentText>1. California Consumer Privacy Act (CCPA):<br />
            If you are a resident of California, you have specific rights regarding your personal information, as granted by the California Consumer Privacy Act (CCPA). We acknowledge your right to:<br />
            (a) Know what personal data is being collected about you;<br />
            (b) Know whether your personal data is sold or disclosed and to whom;<br />
            (c) Say no to the sale of personal data;<br />
            (d) Access your personal data;<br />
            (e) Request the deletion of your personal data;<br />
            (f) Not be discriminated against for exercising your privacy rights.<br />
            For more information or to exercise your rights under the CCPA, please visit our Privacy Notice or contact us at [insert contact information].<br />
            2. Virginia Consumer Data Protection Act (VCDPA):<br />
            If you are a resident of Virginia, the Virginia Consumer Data Protection Act (VCDPA) provides you with specific rights regarding your personal data. These rights include:<br />
            (a) The right to access your personal data;<br />
            (b) The right to correct inaccuracies in your personal data;<br />
            (c) The right to delete your personal data;<br />
            (d) The right to obtain a copy of your personal data in a portable format;<br />
            (e) The right to opt-out of the processing of your personal data for targeted advertising, sale, or profiling purposes.<br />
            For more information or to exercise your rights under the VCDPA, please visit our Privacy Notice or contact us at [insert contact information].<br />
            3. Colorado Privacy Act (CPA):<br />
            If you are a resident of Colorado, the Colorado Privacy Act (CPA) provides you with specific rights regarding your personal data. These rights include:<br />
            (a) The right to access your personal data;<br />
            (b) The right to correct inaccuracies in your personal data;<br />
            (c) The right to delete your personal data;<br />
            (d) The right to obtain a copy of your personal data in a portable and usable format;<br />
            (e) The right to opt-out of the processing of your personal data for targeted advertising, sale, or profiling that produces legal or similarly significant effects.<br />
            For more information or to exercise your rights under the CPA, please visit our Privacy Notice or contact us at [insert contact information].<br />
            4. Contact Information and Requests:<br />
            To exercise any of the rights mentioned above, please contact us via <a href="mailto:support@babyflix.net">support@babyflix.net</a>. We will respond to your request in accordance with the applicable data protection laws. Please note that we may require you to provide additional information to confirm your identity before processing your request.<br />
            5. Updates to this Section:<br />
            We reserve the right to modify this section at any time to reflect changes in the applicable laws or our data practices. We will notify you of any significant changes by posting a notice on our website or by other appropriate means.<br />
            By using our services, you acknowledge that you have read and understood this Compliance with Data Protection Laws section, and you agree to be bound by its terms.</DialogContentText>

    </>
);

export default terms;