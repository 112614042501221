// Packages
import React from 'react';
import { useLocation } from 'react-router-dom';
import Lottie from 'lottie-react';
// Components
import ImageAtom from '../atoms/ImageAtom/ImageAtom';
import Logo from "../../assets/logos/babyflix-logo.png"
import TextAtom from '../atoms/TextAtom';
import SpaceAtom from '../atoms/SpaceAtom';
import tick from "../../assets/svg/successfully-done.json";
import success from "../../assets/svg/success.json";
import ButtonAtom from '../atoms/ButtonAtom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import ShopIcon from '@mui/icons-material/Shop';
const {REACT_APP_BASE_URL} = process.env;

const googlePlayLink = "https://play.google.com/store/apps/details?id=com.babyflix.mobileapp&pcampaignid=web_share";
const iosLink = "https://apps.apple.com/us/app/babyflix/id976499825?platform=iphone";
const web = `${REACT_APP_BASE_URL}/app_home`;
const SuccessPage = () => {
    const location = useLocation();
    const {state} = location;
    // console.log('loc',state);

    const linkHandler=(where)=>{
        window.location.href = where;
    }

    return (
        <div className='full_screen flex_col'>
            <ImageAtom imgSrc={Logo} area={{height : "64px",width : "281px"}}  />
            <div className='flex_col'>
            <Lottie animationData={success} className='lottie-animation' />
            <TextAtom tag={"p"} val={"Account created successfully"} color={"limegreen"} />
            </div>
            <ButtonAtom val='Continue' icon={<ArrowForwardOutlinedIcon/>} onClickFn={()=>linkHandler(`${web}?uuid=${state?.message?.user?.uuid}&from=react`)} />

            <TextAtom tag={"p"} val={"Download our Apps"} color={"grey"} />
            <ButtonAtom val='App Store' icon={<AppleIcon/>} bgColor={"black"} onClickFn={()=>linkHandler(iosLink)}/>
            <ButtonAtom val='Google Play' icon={<ShopIcon/>} bgColor={"black"} onClickFn={()=>linkHandler(googlePlayLink)}/>

        </div>
    )
}

export default SuccessPage;