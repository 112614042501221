import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
    list : [],
    currentTodo : { }
    // mail : '',
    // token : '',
    // finished : false
}

const todoSlice = createSlice({
    name : 'todoCreds',
    initialState,
    reducers : {
        addCreds : (state,action) => {
            state.list.push({...action.payload,finished : false})
        },
        addCurrentTodo : (state,action) => {
            state.currentTodo = action.payload
        },
        addAllCreds : (state,action) => {
            state.list = action.payload
        }
    }
})
export const {addCreds,addCurrentTodo,addAllCreds} = todoSlice.actions;
export default todoSlice.reducer;