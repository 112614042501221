import React from "react";
import styled from "styled-components";

const Div = styled.div`
    height : ${props => props?.customStyle?.height || '100vh'};
    width : ${props => props?.customStyle?.width || '100vw'};
    // border-radius : 10px;
    display : flex;
    justify-content : center;
    align-items : center;
    flex-direction : column;
    ${props => props.id}
`;

const DivAtom = ({children, cssClass,customStyle}) => {
    return <Div customStyle={customStyle} id={cssClass}>{children}</Div>;
}

export default DivAtom;