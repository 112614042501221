import TextField from '@mui/material/TextField';
const varient = ['outlined', 'filled', 'standard']

const InputAtom = ({
    version = varient[0],
    label = "Write something",
    type = "text",
    error = false,
    subText = "",
    feed = () => { },
    name = "",
    req = false,
    changeFn = ()=>{},
    valid = ()=>{}
}) =>
    {
        return(<TextField

        // UI's
        label={label}
        variant={version}
        type={type}
        sx={{
            width: "325px"
        }}
        // Logic's
        {...feed(name,{
            required : req,
            validate : valid || true
        })}
        onChange={changeFn}

        // Info
        error={error}
        helperText={subText}
    />)}

export default InputAtom;