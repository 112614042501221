// Packages
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// Components
import TextAtom from "../atoms/TextAtom";
import "./pageStyle.css";
import ButtonAtom from "../atoms/ButtonAtom";
import ImageAtom from "../atoms/ImageAtom/ImageAtom";
import Logo from "../../assets/logos/babyflix-logo.png"
import SpaceAtom from "../atoms/SpaceAtom";
import InputAtom from "../atoms/InputAtom";
import NumberAtom from "../atoms/NumberAtom";
import CheckboxAtom from "../atoms/CheckboxAtom";
import { checkUser, createAccount } from "../../connections/Api/Prepare";
import AlertAtom from "../atoms/AlertAtom";
import PopupInputAtom from "../atoms/PopupInputAtom";
import { Button } from "@mui/material";
import SelectAtom from "../atoms/SelectAtom";
import OutlineInputAtom from "../atoms/OutlineInputAtom";
import PhoneNumberMolecule from "../molecules/PhoneNumberMolecule";
import PasswordComboMolecule from "../molecules/PasswordComboMolecule";
import LoaderAtom from "../atoms/LoaderAtom";
import ReCAPTCHA from "react-google-recaptcha";
const {REACT_APP_SITE_KEY} = process.env;


const SignUpPage = () => {
    const {register,setValue,getValues, handleSubmit,control,watch, formState: { errors} } = useForm({ 
        defaultValues: { code: "+1", is_accepted_terms : false, captcha : false } 
    });
    const [resdata, setResdata] = useState('');
    const [termsOpen,setTermsOpen] = useState(false);
    const [loading,setLoading] = useState(false);

    const navigate = useNavigate();
// console.log('watc',watch());
// console.log('err',errors);

// ===============FORM HANDLE AND SUBMIT=======================
    const formHandler = async(data) => {

        data.phone = data?.code+data?.phoneNumber?.split("-").join("");
        data.recaptcha_response = data?.captcha;
        setLoading(true);
        setResdata('');
        // check email and phone
        let isAlreadyExist = await checkUser(
            data.email,data.phone
        );

            if(isAlreadyExist?.status === 400){
                // already exist
                setResdata(isAlreadyExist);
                setLoading(false);
                return;
            }

        // then submit

        data.confirmationCode = "";

        let response = await createAccount(data);
        // console.log('submited',response);
        setLoading(false);
        setResdata(response);
        if(response?.status === 200){
            navigate('/confirmation-page',{state : data})
        }
    }
    const handleTerms = () => {
        setTermsOpen(!termsOpen)
    }
    const acceptTermsHandler = () => {
        setTermsOpen(false);
        setValue("is_accepted_terms",true);
    }
    function onChange(value) {
        setValue("captcha",value);
    }
    return (
        <div className="full_screen flex_col">
            <ImageAtom imgSrc={Logo} area={{height : "64px",width : "281px"}}  />
            <div>
                <TextAtom tag={"h5"} val={"Welcome to BabyFlix"} boldIt color={"black"} />
                <TextAtom tag={"body2"} element={"span"} val={"Let’s start with some basic info."} color={"grey"} />
            </div>

            <form className="form_area flex_col" onSubmit={handleSubmit(formHandler)}>
                {resdata && <AlertAtom message={resdata?.message} feel={resdata?.status === 200 ? 'success' : 'warning'} />}
                <div className="grid_screen">
                    
                <InputAtom label={"First Name"} feed={register} name={'fname'} req error={Boolean(errors?.fname)} />

                <InputAtom label={"Last Name"} feed={register} name={'lname'} req error={Boolean(errors?.lname)} />

                <InputAtom label={"Email"} type="email" feed={register} name={'email'} req error={Boolean(errors?.email)} />
                <PhoneNumberMolecule names={["code",'phoneNumber']} 
                                    setter={setValue}
                                    getter={getValues}
                                    feed={register}
                                    control={control}
                                    req
                                    errors={errors}
                                    label={'Phone'}
                />
                <PasswordComboMolecule 
                                    setter={setValue}
                                    getter={getValues}
                                    feed={register}
                                    // control={control}
                                    // req
                                    errors={errors}
                                    // label={'Phone'}
                />
                </div>
                {/* Submit Button */}
                <CheckboxAtom label="I agree to the terms and conditions" name={'is_accepted_terms'} control={control} getter={getValues} />
                {errors?.is_accepted_terms && <TextAtom tag={"p"} element={'small'} val={"Please accept term & condition"} color={"red"} />}
                <Button onClick={handleTerms}>Read Terms & Conditions</Button>
                <ReCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    {...register('captcha',{
                        required : true,
                    })}
                    onChange={onChange}
                />
                {errors?.captcha && <TextAtom tag={"p"} element={'small'} val={"Please verify recaptcha"} color={"red"} />}
                <PopupInputAtom handleClose={handleTerms} open={termsOpen} accept={acceptTermsHandler} />
                <SpaceAtom height={"20px"}/>
                <ButtonAtom val="Submit" type="submit" dontTouch={loading} />
                <LoaderAtom open={loading} />
            </form>
        </div>
    )
}

export default SignUpPage;