import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './components/pages/LandingPage';
import LoginPage from './components/pages/LoginPage/LoginPage';
import SignUpPage from './components/pages/SignUpPage';
import ConfirmationPage from './components/pages/confirmationPage';
import SuccessPage from './components/pages/SuccessPage';
import CaptchaPage from './components/pages/CaptchaPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<LandingPage/>} />
        <Route path='/sign-up' element={<SignUpPage/>} />
        <Route path='/captcha' element={<CaptchaPage/>} />
        <Route path='/confirmation-page' element={<ConfirmationPage/>} />
        <Route path='/success-page' element={<SuccessPage/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
