import React,{useState} from 'react';
import TextAtom from '../atoms/TextAtom';
import InputAtom from '../atoms/InputAtom';
import { passwordStrength } from 'check-password-strength'
import { LinearProgress } from '@mui/material';
import SpaceAtom from '../atoms/SpaceAtom';

const strengthHandler = (val = '') => {
        const strengthObj = passwordStrength(val)?.id;
        switch (strengthObj) {
            case 3:
                return 100;
                case 2:
                    return 60;
                    case 1:
                        return 30;
        
            default:
                return 10;
        }
}

const PasswordComboMolecule = ({
                                    setter=()=>{},
                                    getter=()=>{},
                                    feed=()=>{},
                                    // control={control}
                                    // req
                                    errors=''
                                    // label={'Phone'}
}) => {

    const [progress,setProgress] = useState(0);
    const changeHandler = (e) => {
        const score = strengthHandler(e.target.value);
        // console.log('fckin',score);
        setProgress(score);
    }

    const validate = (val) => {
        if(val?.length < 5){
            return "Password must be 5 characters long."
        }

        if (getter('password') != val) {
        return "Your passwords do no match";
        }
    }

    return (
        <>
            <div>
            <InputAtom 
                    label={"Password"} 
                    type="password" 
                    changeFn={changeHandler} 
                    feed={feed} 
                    name={'password'}
                    valid = {validate} 
                    req
                    subText={errors ? errors?.password?.message : 'Use lowercase, uppercase, number & symbol'}
                    
                    error={Boolean(errors?.password)} />
            <SpaceAtom height='10px' />
            {progress > 0 && <LinearProgress variant="determinate" value={progress} />}
            </div>
            <div>
            <InputAtom 
                    label={"Confirm Password"} 
                    type="password" 
                    feed={feed}
                    valid = {validate}
                    name={'confirm_password'} 
                    req 
                    error={Boolean(errors?.confirm_password)}
                    subText={errors ? errors?.confirm_password?.message : ''}
                    />
            {/* <LinearProgress variant="determinate" value={progress} /> */}
            </div>
        </>
    );

}

export default PasswordComboMolecule;