import { Typography } from "@mui/material";
const Bold = 700;
const Reg = 400;
const TextAtom = ({
    tag="p",
    element = tag,
    val = "...",
    boldIt=false,
    color="white",
    callbackFn,
    ...props
}) =>
    <Typography 
        variant={tag}
        component={element}
        onClick={callbackFn}
        sx={{
                fontWeight : !boldIt ? Reg : Bold,
                color : color,
                cursor : callbackFn ? "pointer" : "default"
            }}
            {...props}
            >
        {val}
    </Typography>

export default TextAtom;