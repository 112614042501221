export default [
    "+1", // US,CA
    "+31", //NL
    "+32", //BE
    "+33", //FR
    "+34", //ES
    "+39", //IT
    "+41", //CH
    "+43", //AT
    "+44", //GB
    "+49", //DE
    "+52", //MX
    "+351", //PT
    "+501", //BZ
    "+502", //GT
    "+503", //SV
    "+504", //HN
    "+505", //NI
    "+506", //CR
    "+507", //PA
]