import { createTheme } from "@mui/material";


const Theme = createTheme({
    palette: {
        primary: {
            main : '#FB8DBF'
        },
        secondary: {
            main : '#3CC5EE'
        },
        text: {
            primary: '#000000', // White text color
        },
        mode: 'light',
    },
    typography: {
        fontFamily: 'Poppins, sans-serif', // Use 'Poppins' as the default font family
    },
    components: {
    MuiOutlinedInput: {
        styleOverrides: {

            notchedOutline: {
                borderRadius: '10px',
            },
            // input: {
            //     color: 'black', // Change the text color to red
            // },
        
        },
    },
    },
    shape : {
        borderRadius : '10px'
    }
});

const DarkTheme = createTheme({
    palette: {
        primary: {
            main : '#FB8DBF'
        },
        secondary: {
            main : '#3CC5EE'
        },
        text: {
            primary: '#ffffff', // White text color
        },
        mode: 'dark',
    },
    typography: {
        fontFamily: 'Poppins, sans-serif', // Use 'Poppins' as the default font family
    },
    components: {
    MuiOutlinedInput: {
        styleOverrides: {

            notchedOutline: {
                borderRadius: '10px',
            },
            // input: {
            //     color: 'black', // Change the text color to red
            // },
        
        },
    },
    },
    shape : {
        borderRadius : '10px'
    }
});

export default Theme;