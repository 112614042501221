import TextField from '@mui/material/TextField';
import MaskAtom from './MaskAtom';
import { OutlinedInput } from '@mui/material';
const varient = ['outlined', 'filled', 'standard']

const OutlineInputAtom = ({
    version = varient[0],
    label = "Write something",
    type = "text",
    error = false,
    subText = "",
    feed = () => { },
    name = "",
    req = false,
    mask = false
}) =>
    {
        return(<OutlinedInput

        // UI's
        label={label}
        variant={version}
        placeholder={"555-555-5555"}

        type={type}
        sx={{
            width: "65%"
        }}
        // Logic's
        {...feed(name,{required : req})}
        inputComponent={MaskAtom}

        // Info
        error={error}
    />)}

export default OutlineInputAtom;