import React,{useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import TextAtom from "../atoms/TextAtom";
import "./pageStyle.css";
import ButtonAtom from "../atoms/ButtonAtom";
import ImageAtom from "../atoms/ImageAtom/ImageAtom";
import Logo from "../../assets/logos/babyflix-logo.png"
import SpaceAtom from "../atoms/SpaceAtom";

const LandingPage = () => {
    const navigate = useNavigate();

    // useEffect(()=>{
    //     fetch("https://stage.babyflix.net/shutterfly/health_check")
    // },[])

    const handleNavigate = () => {
        navigate("/sign-up")
    }
    return (
        <div className="full_screen flex_col">
            <ImageAtom imgSrc={Logo} area={{height : "64px",width : "281px"}}  />
            <div>
                <TextAtom tag={"h5"} val={"Connect with your baby before it’s born"} boldIt color='black' />
                <SpaceAtom height={"20px"}/>
                <TextAtom tag={"p"} val={"Ultrasounds ehanced with Flix 10K"} color='black' />
            </div>
            <div>
                <ButtonAtom val="Create Account" onClickFn={handleNavigate} />
                <SpaceAtom height={"20px"}/>
                <TextAtom tag={"p"} val={"Already a member? Sign In"} color='black' />
            </div>
        </div>
    )
}

export default LandingPage;