// imports
import React from 'react';
import DefaultImage from "../../../assets/images/default-image.png";
import styled from 'styled-components';

const CustomImg = styled.img.attrs(({src})=>({
    src: src,
    alt: 'Article Cover',
}))`
    width : 100%;
    height : 100%;
`;

const CustomPicture = styled.picture`
    width : ${props => props?.area.width || "100%"};
    height :  ${props => props?.area.height || "100%"};
    object-fit: cover;
`;

const ImageAtom = ({
    imgSrc = DefaultImage,
    altVal = "There is no image",
    area,
    srcSets = [{url : "",size : "650px"}],
}) => {
    return (
        <CustomPicture area={area}>
            {/* {srcSets.map(curSource => <source srcSet={curSource?.url} media={`min-width:${curSource?.size}`} />)} */}
            <CustomImg src={imgSrc} alt={altVal}  />
        </CustomPicture>
    )
}

export default ImageAtom;