import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';

const CheckboxAtom = ({
    label = "Checkbox label",
    name = "",
    control = () => { },
    callFn = () => { },
    getter = () =>{}
}) => {
    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    onClick={callFn}
                    // defaultValue={false} // Set the initial value
                    rules={{
                        required: true, // Pass your custom validation function
                    }}
                    render={({ field }) => (
                        <Checkbox
                            {...field}
                            color="primary"
                            checked={getter(name)}
                        />
                    )}
                />
            }
            label={label}
        />)
}
export default CheckboxAtom;