import axios from 'axios';
const {REACT_APP_BASE_URL} = process.env;

const api = axios.create({
    baseURL : REACT_APP_BASE_URL || "https://stage.babyflix.net",
    headers: {
        'Content-Type': 'application/json', // Set the content type for POST requests
    },
});


export const createAccount = async(data) => {
    // console.log('rec data',data);
    data.username = data?.email;
    try {
        const res = await api.post("/server/services/babyflix/user_register_cognito.json",data);
        // console.log(res);
        return res?.data;
    
    } catch (error) {
        // console.log('errr',error);
        return error;
    }
}

export const healthCheck = () => {
    axios('https://stage.babyflix.net/shutterfly/health_check');
}

export const checkUser = async(email = '',phone = '') => {
    const jsonData = {email,phone};
    // console.log(email,phone);
    // const queryParams = new URLSearchParams(jsonData).toString();
    try {
        // const res = await api.post(`/createUserVerification?${queryParams}`);
        const res = await api.post('/createUserVerification',jsonData);
        // console.log(res);
        return res?.data;
    
    } catch (error) {
        // console.log('errr',error);
        return error;
    }
}

export const personalize = () => {
    const url = "https://stage.babyflix.net/server/services/babyflix/create_family_member.json";
    const load = {
        "uid": 2264, // Numeric value for the Drupal user ID
        "birth_date": "2023-01-15", // Date format (YYYY-MM-DD) for Baby's birth date
        "baby_name": "John Doe", // String for the Baby's name
        "gender": "Male", // Gender of Baby (Male, Female, Other)
        "mother_birthDate": "1985-05-20", // Date format (YYYY-MM-DD) for Mother's birth date
        // "file_data": "iVBORw0KGgoAAAANSU”,
        "created_date": "", // Timestamp for the created date
        "updated_date": "" // Timestamp for the updated date
        }
    api.post(url,load);
}