// Packages
import React,{useState, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import ImageAtom from '../atoms/ImageAtom/ImageAtom';
import Logo from "../../assets/logos/babyflix-logo.png"
import TextAtom from '../atoms/TextAtom';
import InputAtom from '../atoms/InputAtom';
import SpaceAtom from '../atoms/SpaceAtom';
import OtpAtom from '../atoms/OtpAtom';
import { createAccount } from '../../connections/Api/Prepare';
import AlertAtom from '../atoms/AlertAtom';
import LoaderAtom from "../atoms/LoaderAtom";

const ConfirmationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {state} = location;
    const [responseState,setResponse] = useState('');
    const [loading,setLoading] = useState(false);

    // craeting ref to control child component
    const childRef = useRef();
    const clearOtp = () => {
        childRef.current.resetHandler();
        setResponse('');
    }
    const submitHandler = async(otp) => {
        setLoading(true);
        const data = {...state};
        data.confirmationCode = otp;
        data.recaptcha_response = data?.captcha || "verified";
        const res = await createAccount(data);
        // const isError = JSON?.parse(res?.message)?.error || false;
        setLoading(false);
        if(res?.status === 200){
            navigate('/success-page',{state : res});
        }else{
            // alert(res?.message);
            setResponse(res);
        }
    }

    return (
        <div className='full_screen flex_col'>
            <ImageAtom imgSrc={Logo} area={{height : "64px",width : "281px"}}  />
            <div className='flex_col'>
            <TextAtom tag={"h5"} val={"Confirmation Code sent as Text Message"} boldIt color={"grey"} />
            {responseState?.message && <AlertAtom message={responseState?.message} feel={responseState?.status === 200 ? 'success' : 'warning'} />}
            {/* {loading && <AlertAtom message={"Loading..."} feel={'info'} />} */}

            <SpaceAtom />
            {/* <InputAtom label={"Enter code here"} type='number' /> */}
            <OtpAtom callbackFn={submitHandler} ref={childRef} />
            <SpaceAtom />
            {responseState && responseState?.status !== 200 && <TextAtom callbackFn={clearOtp}  element={"small"} tag={"p"} val={"Try Again"} color={"pink"} />}

            </div>
            <LoaderAtom open={loading} />
        </div>
    )
}

export default ConfirmationPage;